<template>
  <div>

    <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ name: 'index' }">主页</el-breadcrumb-item>
      <el-breadcrumb-item :to="{name: 'message.check'}">微信通知 - 核销通知</el-breadcrumb-item>
      <el-breadcrumb-item> 核销计划</el-breadcrumb-item>
    </el-breadcrumb>

    <br>

    <!----- 搜索框 start------->
    <el-form :inline="true" class="search">
      <el-form-item label="筛选计划" prop="check_id">
        <el-select
            v-model="searchData.check_id"
            filterable
            multiple
            style="width:100%">
          <el-option
              v-for="(item, index) in proxy.checks"
              :key="index"
              :label="item.name"
              :value="item.id"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="时间" prop="date">
        <el-date-picker
            v-model="searchData.date"
            type="daterange"
            start-placeholder="开始时间"
            end-placeholder="结束时间"
            value-format="YYYY-MM-DD"
        />
      </el-form-item>
      <el-form-item>
        <el-button @click="getTask()">查询</el-button>
      </el-form-item>

      <el-form-item style="float: right">
        <el-button type="warning" @click.stop="clickExport()">导出</el-button>
        <el-button type="success" @click.stop="clickShare()">分享</el-button>
        <el-button type="primary" @click.stop="clickCreate()">添加计划</el-button>
      </el-form-item>
    </el-form>
    <!----- 搜索框 end------->

    <!----- 表格 start------->
    <el-table
        column-key="id"
        ref="table.check.task"
        :data="tableData"
        :border="true"
        empty-text="暂无数据"
        style="width: 100%">
      <el-table-column type="selection" width="40"/>
      <el-table-column label="任务名" width="150">
        <template #default="scope">
          <span class="ellipsis" v-text="proxy.check_proxy[scope.row.check_id]"></span>
        </template>
      </el-table-column>
      <el-table-column prop="date" label="日期" />
      <el-table-column prop="project" label="项目" min-width="250" />
      <el-table-column prop="price" label="单价" />
      <el-table-column label="数量" >
        <template #default="scope">
          <span v-if="scope.row.number === 1">
            {{scope.row.task_num}}
          </span>
          <span v-else>
             {{scope.row.task_num}} x {{scope.row.number}}
          </span>
        </template>
      </el-table-column>
      <el-table-column label="上传/发送">
        <template #default="scope">
          <span>
            {{scope.row.upload_num}} / {{scope.row.send_num}}
          </span>
        </template>
      </el-table-column>
      <el-table-column label="付款/退款">
        <template #default="scope">
          <span>
            {{scope.row.paid_price}} - {{scope.row.refund_price}}
          </span>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="150">
        <template #default="scope">
          <el-button v-show="scope.row.status === 1 && scope.row.upload_num > 0" type="warning" size="small" @click="clickAudit(scope.row)">审核</el-button>
          <el-button  v-show="scope.row.upload_num <= 0" type="primary" size="small" @click="clickEdit(scope.row)">修改</el-button>
          <el-button v-show="scope.row.upload_num <= 0" type="danger" size="small" @click="clickDestroy(scope.row)">删除</el-button>
          <el-button type="success" size="small" @click="clickCopy(scope.row)">复制</el-button>
          <el-button type="primary" size="small" @click="clickPaid(scope.row)">改价</el-button>
        </template>
      </el-table-column>
    </el-table>
    <!----- 表格 end------->

    <!----- 分页 start------->
    <div class="cherry-page" v-show="pageData.total">
      <el-pagination
          small
          background
          layout="prev, pager, next, total"
          :total="pageData.total"
          :page-size="searchData.per_page"
          v-model:current-page="searchData.page"
          @update:current-page="getTask()"
          class="mt-4"
      />
    </div>
    <!----- 分页 end------->

    <!-------- 弹出框表单 start ---------->
    <el-dialog v-model="formData.show" title="编辑计划" fullscreen append-to-body>
      <el-form
          ref="check.task"
          label-width="100px"
          :rules="formData.rules"
          :model="formData.data"
      >
        <el-form-item label="关联通知" prop="check_id">
          <el-select
              v-model="formData.data.check_id"
              filterable
              style="width:100%">
            <el-option
                v-for="(item, index) in proxy.checks"
                :key="index"
                :label="item.name"
                :value="item.id"
            />
          </el-select>
        </el-form-item>

        <el-form-item label="项目名称" prop="project">
          <el-input v-model="formData.data.project" placeholder="输入项目名称"/>
        </el-form-item>

        <el-form-item label="项目价格" prop="price">
          <el-input v-model="formData.data.price" type="number" placeholder="请输入项目价格">
            <template #append>元</template>
          </el-input>
        </el-form-item>

        <el-form-item label="购买链接" prop="link">
          <el-input type="textarea" rows="5" v-model="formData.data.link" placeholder="填写购买地址"/>
        </el-form-item>

        <el-form-item label="每单数量" prop="number">
          <el-input-number  v-model="formData.data.number" :min="1"/>
        </el-form-item>

        <el-form-item label="购买单数" prop="task_num">
          <el-input-number  v-model="formData.data.task_num" :min="1"/>
        </el-form-item>

        <el-form-item label="时间" prop="date">
          <el-date-picker
              v-model="formData.data.date"
              editable
              clearable
              value-format="YYYY-MM-DD"
              type="date"
              placeholder="选择日期"
          />
        </el-form-item>
      </el-form>

      <template #footer>
        <span class="dialog-footer">
          <el-button @click="closeDialog">取消</el-button>
          <el-button type="primary" @click="onSubmit">提交</el-button>
        </span>
      </template>
    </el-dialog>
    <!-------- 弹出框表单 end ---------->

    <!-------- 弹出框表单 start ---------->
    <el-dialog v-model="shareData.show" title="分享计划" fullscreen append-to-body>
      <el-form
          ref="check.task.share"
          label-width="100px"
          :rules="shareData.rules"
          :model="shareData.data"
      >
      </el-form>

      <el-table
          column-key="id"
          ref="table.check.task"
          :data="shareData.data.rows"
          :border="true"
          empty-text="暂无数据"
          style="width: 100%">
        <el-table-column prop="date" label="日期" />
        <el-table-column label="项目">
          <template #default="scope">
            <div><b v-text="scope.row.project"></b></div>
          </template>
        </el-table-column>
        <el-table-column prop="price" label="项目价格" />
        <el-table-column prop="number" label="每单数量" />
        <el-table-column prop="task_num" label="购买单数" />
      </el-table>

      <br/>

      <el-form-item label="分享名称" prop="task_num">
        <el-input  v-model="shareData.data.name" placeholder="输入展示名称"/>
      </el-form-item>

      <el-form-item label="选择权限" prop="roles">
        <el-select v-model="shareData.data.roles" multiple>
          <el-option label="查看" value="view"></el-option>
          <el-option label="上传" value="upload"></el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="上传限制" prop="limit">
        <el-select v-model="shareData.data.limit">
          <el-option label="不限" :value="-1"></el-option>
          <el-option label="每人一次" :value="1"></el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="有效时间" prop="lifetime">
        <el-select v-model="shareData.data.lifetime">
          <el-option label="1天" :value="86400"></el-option>
          <el-option label="3天" :value="259200"></el-option>
          <el-option label="7天" :value="604800"></el-option>
          <el-option label="15天" :value="1296000"></el-option>
          <el-option label="30天" :value="2592000"></el-option>
        </el-select>
      </el-form-item>

      <el-divider />

      <el-input v-model="shareData.share_url" readonly/>

      <template #footer>
        <span class="dialog-footer">
          <el-button @click="closeShareDialog">取消</el-button>
          <el-button type="primary" @click="onShareSubmit">提交</el-button>
        </span>
      </template>
    </el-dialog>
    <!-------- 弹出框表单 end ---------->

    <!-------- 弹出框表单 start ---------->
    <el-dialog v-model="paid.show" title="分享计划" fullscreen append-to-body>
      <el-form
          ref="check.task.paid"
          label-width="100px"
          :rules="paid.rules"
          :model="paid.data"
      >
      </el-form>

      <el-form-item label="已付金额" prop="paid_price">
        <el-input  v-model="paid.data.paid_price" placeholder="输入已支付的金额"/>
      </el-form-item>

      <el-form-item label="退款金额" prop="refund_price">
        <el-input  v-model="paid.data.refund_price" placeholder="输入退款的金额"/>
      </el-form-item>

      <template #footer>
        <span class="dialog-footer">
          <el-button @click="closePaidDialog">取消</el-button>
          <el-button type="primary" @click="onPaidSubmit">提交</el-button>
        </span>
      </template>
    </el-dialog>
    <!-------- 弹出框表单 end ---------->
  </div>
</template>

<script>
  import {apiGetChecks} from '@/api/wechatCheckApi'
  import {
    apiGetWechatCheckTask,
    apiCreateWechatCheckTask,
    apiUpdateWechatCheckTask,
    apiDestroyWechatCheckTask,
    apiCreateWechatCheckTaskShare,
    apiAuditWechatCheckTask,
    apiExportWechatCheckTask,
    apiUpdateWechatCheckTaskPaid
  } from '@/api/wechatTaskApi'
  const form = {
    id: 0,
    check_id: '',
    project: '',
    price: '',
    link: '',
    number: 1,
    task_num: 1,
    date: '',
  }

  export default {
    name: 'message.check.task',
    created () {
      this.getChecks()
      this.getTask()
    },
    data () {
      return {
        searchData: {
          name: '',
          date: [],
          check_id: [],
          page: 1,
          per_page: 15,
        },
        pageData: {
          total: 0,
        },
        proxy: {
          checks: [],
          check_proxy: []
        },
        tableData: [],
        paid: {
          show: false,
          rules: {
            paid_price: [{required: true, message: '填写支付金额', trigger: 'blur',}],
            refund_price: [{required: true, message: '填写退款金额', trigger: 'blur',}],
          },
          data: {
            id: 0,
            paid_price: 0,
            refund_price: 0,
          }
        },
        shareData: {
          show: false,
          share_url: '',
          data: {
            name: '',
            limit: -1,
            ids: [],
            rows: [],
            roles: ['view', 'upload'],
            lifetime: 259200
          }
        },
        // 创建表单
        formData:{
          data: form,
          rules: {
            check_id: [{required: true, message: '请选择关联通知', trigger: 'blur',}],
            project: [{required: true, message: '项目名', trigger: 'blur',}],
            price: [{required: true, message: '价格', trigger: 'blur',}],
            link: [{required: true, message: '输入购买地址', trigger: 'blur',}],
            number: [{required: true, message: '填写每单数量', trigger: 'blur',}],
            task_num: [{required: true, message: '填写单数', trigger: 'blur',}],
            date: [{required: true, message: '请选择日期', trigger: 'blur',}],
          },
          show: false,
        },
      }
    },
    methods: {
      // 核销计划列表
      getTask () {
        apiGetWechatCheckTask(this.searchData).then((data) => {
          this.tableData = data.data
          this.pageData = data.meta
        })
      },
      // 获取核销
      getChecks () {
        apiGetChecks({per_page: 150}).then((data) => {
          this.proxy.checks = data.data
          data.data.forEach(item => {
            this.proxy.check_proxy[item.id] = item.name
          })
        })
      },
      // 审核
      clickAudit (row) {
        apiAuditWechatCheckTask(row.id).then(() => {
          this.$message.success('审核成功')
          this.getTask()
        })
      },
      // 导出
      clickExport () {
        let rows = this.$refs['table.check.task'].getSelectionRows();

        if (!rows.length) {
          this.$message.warning('请先选择导出内容')
          return
        }

        let ids = []
        rows.forEach((item) => {
          ids.push(item.id)
        })

        window.open(apiExportWechatCheckTask(ids))
      },
      // 分享链接
      clickShare () {
        let rows = this.$refs['table.check.task'].getSelectionRows();

        if (!rows.length) {
          this.$message.warning('请先选择内容')
          return
        }

        this.shareData.data.ids = []
        rows.forEach((item) => {
          this.shareData.data.ids.push(item.id)
        })
        this.shareData.data.rows = rows
        this.showShareDialog()
      },
      clickPaid (row) {
        this.paid.data = {
          id: row.id,
          paid_price: row.paid_price,
          refund_price: row.refund_price
        }
        this.showPaidDialog()
      },
      // 提交支付数据
      onPaidSubmit () {
        this.$refs['check.task.paid'].validate((valid) => {
          if (valid) {
            apiUpdateWechatCheckTaskPaid(this.paid.data.id, this.paid.data).then(() => {
              this.$message.success('修改成功')
              this.getTask()
              this.closePaidDialog()
            })
          }
        })
      },
      // 提交分享数据
      onShareSubmit() {
        this.$refs['check.task.share'].validate((valid) => {
          if (valid) {
            apiCreateWechatCheckTaskShare(this.shareData.data).then((data) => {
              this.$message.success('创建成功')
              this.shareData.share_url = window.location.protocol +
                  "//" +
                  window.location.host +
                  '/share/check-task/' + data.share_code
            })
          }
        })
      },
      // 提交数据
      onSubmit () {
        this.$refs['check.task'].validate((valid) => {
          if (valid) {
            if (this.formData.data.id) {
              apiUpdateWechatCheckTask(this.formData.data.id, this.formData.data).then(() => {
                this.$message.success('修改成功')
                this.getTask()
                this.closeDialog()
              })
            } else {
              apiCreateWechatCheckTask(this.formData.data).then(() => {
                this.formData.data = form
                this.$message.success('创建成功')
                this.getTask()
                this.closeDialog()
              })
            }
          }
        })
      },
      // 删除
      clickDestroy (row) {
        this.$messageBox.confirm('确定删除吗').then(() => {
          apiDestroyWechatCheckTask(row.id).then(() => {
            this.getTask()
            this.$message.success('删除成功')
          })
        })
      },
      clickEdit (row) {
        this.formData.data = row
        this.showDialog()
      },
      clickCopy(row) {
        this.formData.data = row
        this.formData.data.id = 0
        this.formData.data.date = ''
        this.formData.data.task_num = 1
        this.showDialog()
      },
      clickCreate () {
        this.formData.data = form
        this.showDialog()
      },
      showPaidDialog() {
        this.paid.show = true
      },
      closePaidDialog () {
        this.paid.show = false
      },
      showShareDialog() {
        this.shareData.show = true
      },
      closeShareDialog () {
        this.shareData.show = false
      },
      // 显示弹窗
      showDialog() {
        this.formData.show = true
      },
      closeDialog() {
        this.formData.show = false
      },
    }
  }
</script>
<style scoped>
.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  max-width: 5em;
}
</style>
