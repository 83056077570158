import {httpGet, httpPost, httpPut, httpDelete} from '@/plugins/request'
import {getApiUrl} from '@/plugins/common'
import {getAccessToken} from '@/plugins/auth'


/**
 * 计划任务列表
 * @returns {Promise<*>}
 */
export function apiGetWechatCheckTask( query = []) {
    return httpGet(`/manage/wechat/task`, query)
}

/**
 * 创建计划
 *
 * @param data
 * @returns {Promise<*>}
 */
export function apiCreateWechatCheckTask(data) {
    return httpPost(`/manage/wechat/task`, data)
}

/**
 * 修改计划
 *
 * @param id
 * @param data
 * @returns {Promise<*>}
 */
export function apiUpdateWechatCheckTask(id, data) {
    return httpPut(`/manage/wechat/task/${id}`, data)
}

/**
 * 修改计划支付金额
 *
 * @param id
 * @param data
 * @returns {Promise<*>}
 */
export function apiUpdateWechatCheckTaskPaid(id, data) {
    return httpPut(`/manage/wechat/task/${id}/paid`, data)
}

/**
 * 删除计划
 *
 * @param id
 * @returns {Promise<*>}
 */
export function apiDestroyWechatCheckTask(id) {
    return httpDelete(`/manage/wechat/task/${id}`)
}


/**
 * 删除计划
 *
 * @param id
 * @returns {Promise<*>}
 */
export function apiAuditWechatCheckTask(id) {
    return httpPost(`/manage/wechat/task/${id}/audit`)
}

/**
 * 创建计划任务分享
 * @param data
 * @returns {Promise<*>}
 */
export function apiCreateWechatCheckTaskShare(data)
{
    return httpPost('/manage/wechat/task/share', data)
}

/**
 * 导出计划
 *
 * @param id id的集合
 * @returns {Promise<*>}
 */
export function apiExportWechatCheckTask(id) {
    return getApiUrl(`/manage/wechat/task/export`, {id: id, token: getAccessToken()})
}
